<template>
  <div class="task-container">
<!--    <van-search v-model="value" placeholder="请输入搜索关键词" />-->
    <div class="pro-nums" v-if="listData.length>0">结算总额
      <span class="nums">{{count || 0}}</span>元
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        success-text="刷新成功"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getList"
      >
        <div v-for="(fa,i) in listData" :key="i">
          <div class="task-list" v-for="(item,index) in fa" :key="item.sId" >
            <div class="task-mid">
              <div class="task-name van-ellipsis">项目名称:{{item.name}}</div>
              <div class="task-name max-width van-ellipsis">发布企业:{{item.merchantName}}</div>
              <div class="task-name van-ellipsis">发布时间:{{item.createTime}}</div>
              <div class="task-name van-ellipsis">薪酬:{{item.salaryRange}}</div>
              <div class="task-name van-ellipsis">项目描述:{{item.describe}}</div>
              <div class="task-type">
                <van-button class="task-pz" @click="taskInfo(item)">任务凭证</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-empty description="没有更多数据了" v-if="!listData.length > 0" />
    <footer-menu></footer-menu>
  </div>
</template>
<script>
import footerMenu from '@/components/footer/FooterMenu'
import requestHTAPI from '@/axios/HantangTax'
export default {
  name: 'settlement',
  data () {
    return {
      value: '',
      listData: [],
      result: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      count: 0,
      formData: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  components: {
    footerMenu
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      requestHTAPI.settList({
        ...this.formData
      }).then(res => {
        this.loading = false
        if (res.data.code === '0000') {
          const data = res.data.data
          if (this.listData.length > 0 && data.pageNum === 1) {
            return
          }
          this.formData.pageNum = data.pageNum
          this.formData.pageSize = data.pageSize
          this.formData.pageNum++
          this.total = data.total
          this.count = data.count
          this.listData.push(data.rows)
          this.result = Array.prototype.concat.apply([], this.listData)

          if (this.result.length >= this.total) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onRefresh () {
      // 清空列表数据
      if (this.refreshing) {
        this.formData.pageNum = 1
        this.listData = []
        this.refreshing = false
      }
      this.finished = false
      this.loading = true
      this.getList()
    },
    taskInfo (item) {
      this.$router.push({
        path: '/mine/settlementInfo',
        query: {
          id: item.sId
          // name:item.name,
          // merchantName:item.merchantName,
          // createTime:item.createTime,
          // salaryRange:item.salaryRange,
          // describe:item.describe,
        }
      })
    }
    // taskInfo (id) {
    //   this.$router.push({
    //     name: 'settlementInfo',
    //     query: {
    //       id
    //     }
    //   })
    // }
  }
}
</script>
<style scoped lang="less">
  .task-container{
    height: calc(100% - 100px);
  }
  .taskbox{
    margin-top: 40px;
    overflow: hidden;
  }
  .task-list{
    background: #fff;
    height: 220px;
    overflow: hidden;
    margin: 0px 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0vw 15px 30px -15px rgb(187, 187, 187 , 50%);
  }

  .task-mid{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    box-sizing: border-box;
    text-align: left;
    justify-content: space-around;
    flex-flow: column nowrap;
    flex: 1;
    position: relative;

    .task-name{
      font-size: 26px;
      color: #333;
      padding-right:180px;
      position: relative;
      /*width: 500px;*/
      /*overflow: hidden;*/
      /*text-overflow: ellipsis;*/
      /*white-space:nowrap;*/
    }
    .max-width{
      padding-right: 20px;
    }
    .task-type{
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: #d40505;
    }
  }
  .pro-nums{
    text-align: left;
    padding: 10px 40px;
    font-size: 30px;
    font-weight: bold;
    .nums{
      color: red;
    }
  }
  .task-pz{
    width: 150px;
    height: 50px;
    font-size: 20px;
    padding: 0 10px;
    border-radius: 10px;
    background: #e86666;
    color: #fff;
    border: none;
  }

</style>
